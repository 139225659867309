import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis pt-0">
        <div className='gray-bg'>
        <div className="container">
              <h2 className="heading-h2 text-center"><span className='heading-h3 bluecolor'>What is</span> White Label Crypto Wallet?</h2>
            <div className="text-center">
              <p className="pharagraph text-center">White Label crypto wallet is a pre-built, multi tested, and customizable solution for storing, sending, receiving coins & tokens, NFTs, Stablecoins, and other assets. White label Wallet development demands solid domain knowledge and hands-on experience. At this point, one should connect with the top White-label providing company, such as Coinsclone.
              </p>
              <p className="pharagraph text-center mb-0">Coinsclone will be a go-to solution to construct both custodial and non-custodial wallets compatible with mobile phones and desktops. With more than 10 years of experts in cryptocurrency wallet development services, our professionals offer prompt solutions equipped with grabbing features and functionalities that are tailored to meet the demands of your business. In addition, we offer customers access to advanced security features to guard against security breaches and hackers. For more workable solutions - reach out to us now!
              </p>
            </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis