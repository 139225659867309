import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'
import ButtonComponent from '../ButtonComponent/ButtonComponent'


class BannerSection extends React.Component {


  render() {

    return (
      <section className="banner alngpad wallet-banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 left-side mb-3 mb-lg-0">
                <h1 className="main-heading"><span className='bluecolor'>White Label</span> Crypto Wallet</h1>
                <p className="pharagraph">Explore a pre-compiled White label Crypto Wallet to illuminate your crypto path. Curate your personalized wallet to stay on track with Gen Z.</p>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 right-side">
              <StaticImage 
                src="https://coinsclone.mo.cloudinary.net/images/whitelabel-wallet/white-label-crypto-wallet.webp"
                alt="White Label Crypto Wallet banner"
                className='d-none d-md-block'
                width={743}
              /> 
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default BannerSection